import type { ComputedRef, Ref } from 'vue';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

export function useHTMLDictionary<TKey extends string>(
  keys: Ref<readonly TKey[]> | readonly TKey[]
): ComputedRef<Partial<Record<TKey, string>>> {
  const { $dictionary } = useNuxtApp();

  return computed(() => {
    const keyHtmlMap: Partial<Record<TKey, string>> = {};
    for (const key of unref(keys)) {
      const value = $dictionary.value[key];
      if (!value) {
        continue;
      }

      keyHtmlMap[key] = documentToHtmlString(value);
    }

    return keyHtmlMap;
  });
}
